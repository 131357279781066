import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Arrow from '@/assets/img/icons/ico_processor_arrow@2x.png';
import Bn from '@/assets/img/mission/convention_bn@2x.png';
import Button from '@/components/Button';
import ButtonMoreView from '@/components/ButtonMoreView';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import GoalsPopup from '@/components/Layer/GoalsPopup';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

const boxData = [
  {
    id: 0,
    tit: `무력분쟁으로부터의 아동보호`,
    dec: `아동의 무력분쟁 관여에 관한 선택의정서(Optional Protocol to the Convention on the Rights of the Child on the involvement of children in armed conflict, OPAC)는 협약의 이행을 강화하고 무력분쟁 중 아동보호를 강화하기 위한 노력으로 만들어졌습니다. 선택의정서에 따르면 국가는 만 18세 미만의 군대 구성원이 적대 행위에 직접 참여하지 않도록 ‘가능한 모든 조치를 취해야’합니다. 국가는 또한 군대에 자발적으로 모집할 수 있는 최소 연령을 만 15세로 제한하고 있습니다. 그리고 만 18세 미만의 아동은 특별 보호를 받을 자격이 있으므로 만 18세 미만의 자발적인 모집에는 충분한 보호장치가 포함되어야 함을 상기시킵니다. 또한 만 18세 미만의 강제 모집을 금지합니다.`,
  },
  {
    id: 1,
    tit: `아동매매·성매매를 통한 아동성착취·아동성착취물로부터 아동보호`,
    dec: `아동매매·성매매를 통한 아동성착취·아동성착취물에 관한 선택의정서(Optional Protocol to the Convention on the Rights of the Child on the sale of children, child prostitution and child pornography, OPSC)는 아동권리에 대한 심각한 침해의 범죄화에 특별한 관심을 기울이고 있으며 이를 방지하기 위한 노력에 있어 대중의 인식과 국제협력의 중요성을 강조합니다. 이는 어린이에 대한 성적 착취와 학대를 종식시키기 위한 세부 요건을 국가에 제공함으로써 아동권리협약을 보완하고, 다양한 형태의 강제 노동, 불법 입양 및 장기 기증과 같은 목적으로 아동이 거래되는 것을 방지합니다. 이 선택의정서는 ‘아동매매’, ‘성매매를 통한 아동성착취’, ‘아동성착취물’과 관련된 범죄에 대한 정의를 제공합니다. 또한 이러한 범죄와 관련된 활동을 범죄화하고 처벌할 정부의 의무를 부여합니다. 성적 착취, 영리 또는 강제 노동을 위해 신체 일부나 아동을 양도할 목적으로 아동을 거래하거나 출산하는 사람뿐만 아니라 이러한 활동을 위해 아동을 착취하는 사람에게도 처벌이 필요합니다. 선택의정서는 또한 피해 아동권리와 이익을 보호합니다. 정부는 피해 어린이에게 법률 및 기타 지원 서비스를 제공해야 합니다. 그리고 관련된 형사사법제도 내에서 아동의 최선의 이익이 고려해야 합니다. 어린이는 또한 재활과 이를 돕기 위한 지원, 심리치료, 물적 및 재정적 지원을 받아야 합니다. 아동권리협약을 보완하기 위해 선택의정서 조문의 해석은 언제나 아동권리협약의 일반 원칙인 비차별, 아동 최선의 이익, 생존과 발달의 권리, 아동의견 존중의 원칙에 따라 이루어져야 합니다.`,
  },
  {
    id: 2,
    tit: `아동의 개인청원절차`,
    dec: `개인청원절차에 관한 선택의정서(Optional Protocol to the Convention on the Rights of the Child on acommunications procedure, OPIC)는 아동권리위원회가 아동권리 침해에 대한 불만을 접수할 수 있는 근거를 마련했습니다. 선택의정서를 비준한 국가의 아동은 국가법률시스템이 아동권리 침해·위반에 대한 구제방안을 제공할 수 없는 경우 선택의정서를 적용할 수 있습니다. 아동권리위원회는 선택의정서를 비준한 국가의 아동, 특정 아동집단 또는 대표로부터 불만을 접수할 수 있으며 중대한 아동권리 침해가 발생했을 경우 정부의 허가 아래 이에 대한 조사에 착수할 수 있습니다.`,
  },
];

const BoxFlex = styled.ul`
  align-items: stretch !important;

  & > li {
    width: 50%;
    margin-bottom: 32px;

    &.full {
      width: 100%;
    }
  }
  dl {
    height: 100%;
    padding: 60px 40px 76px;
    border-radius: 19px;
    box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;

    dt {
      color: #b7b8ba;
      padding-bottom: 24px;
      ${Tit} {
        margin-right: 8px;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    & > li {
      width: 100%;
      min-height: 228px;
      margin-bottom: 24px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
      dl {
        padding: 24px;
        dt {
          padding-bottom: 12px;
          ${Tit} {
            display: block;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
`;

const SwiperCustom = styled(SwiperBasic)`
  padding: 0;
  margin: 0 -20px;
  width: auto;

  .swiper-container {
    overflow: hidden;
  }
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    width: 100%;
    padding: 0 20px;
    height: auto;

    dl {
      height: 100%;
      padding: 24px;
      border-radius: 19px;
      box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;

      dt {
        color: #b7b8ba;
        padding-bottom: 12px;
        ${Tit} {
          display: block;
          margin-bottom: 8px;
        }
      }
    }
  }
`;
const Box = styled.div`
  padding: 32px 40px;
  background-color: #f8f9fa;

  ${Tit} {
    display: block;
    margin-bottom: 16px;
  }
  ${breakpoint(`tablet`)} {
    padding: 24px 20px;
  }
`;
const ProcessContainer = styled.ul`
  max-width: 872px;
  margin: 0 auto;
  margin-top: 84px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  & > li {
    padding: 0 42px;
    width: 50%;
    margin-bottom: 83px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 58px;
      height: 40px;
      background: url(${Arrow}) center no-repeat;
      background-size: cover;
      position: absolute;
    }

    &:nth-child(5),
    &:nth-child(6) {
      padding-bottom: 0;
    }

    &:nth-child(1) {
      order: 1;
      &::before {
        right: -16px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 4;
    }
    &:nth-child(4) {
      order: 6;
    }
    &:nth-child(5) {
      order: 8;
      &::before {
        left: -16px;
        top: 50%;
        transform: translate(0, -50%) rotate(180deg);
      }
    }
    &:nth-child(6) {
      order: 7;
    }
    &:nth-child(7) {
      order: 5;
    }
    &:nth-child(8) {
      order: 3;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      &::before {
        left: 50%;
        bottom: -51px;
        transform: translate(-50%, 0) rotate(90deg);
      }
    }
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      &::before {
        left: 50%;
        top: -51px;
        transform: translate(-50%, 0) rotate(-90deg);
      }
    }
  }
  .box {
    height: 100%;
    padding: 40px 40px 44px;
    border-radius: 16px;
    box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;

    .no {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #1cabe2;
      border-radius: 100%;
      color: #fff;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .dec {
      color: #1cabe2;
      font-weight: 700;
    }
  }

  ${breakpoint(`tablet`)} {
    margin-top: 71px;
    margin-left: -27px;
    margin-right: -27px;
    & > li {
      padding: 0 27px;
      margin-bottom: 47px;

      &::before {
        width: 30px;
        height: 21px;
      }

      &:nth-child(1) {
        &::before {
          right: -5px;
        }
      }
      &:nth-child(5) {
        &::before {
          left: -5px;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        &::before {
          bottom: -27px;
        }
      }
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        &::before {
          top: -27px;
        }
      }
    }

    .box {
      padding: 26px 16px;
      min-height: 256px;
      word-break: break-all;
    }
  }
  ${breakpoint(`mobile`)} {
    .dec {
      br {
        display: none;
      }
    }
  }
`;
const AgreementContainer = styled.div`
  dl {
    dt,
    dt {
      display: block;
      width: 100%;
    }
    dt {
      padding: 20px 32px;
      background-color: #e2f0f6;
      line-height: 1.6;
    }
    dd {
      padding: 32px;
      padding-bottom: 96px;
    }
  }

  .dec-wrap {
    margin-top: 47px;

    .dec {
      margin-bottom: 68px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    ${Tit} {
      display: inline-block;
      line-height: 1.6;
      padding: 4px;
      background-color: #1cabe2;
    }
  }
  ${Box} {
    margin-top: 64px;
    margin-bottom: 32px;
    .blit-list {
      color: #1cabe2;

      & > li {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .link-blank {
    padding-right: 0;
    text-decoration: underline;
    cursor: pointer;
    &::after {
      position: relative;
      display: inline-block;
      vertical-align: bottom;
    }
    &:hover {
      text-decoration: none;
    }
  }

  ${breakpoint(`tablet`)} {
    dl {
      dt {
        padding: 15px 20px;
      }
      dd {
        padding: 22px 20px 96px;
        padding-bottom: 72px;
      }
    }
    ${Box} {
      margin-top: 48px;
      margin-bottom: 42px;
    }
  }
`;

const SectionTop = styled(Section)`
  article {
    padding-top: 96px;
  }

  ${BoxFlex} {
    margin-top: 32px;
    & > li {
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }

    p {
      word-break: break-all !important;
    }
  }

  ${breakpoint(`tablet`)} {
    ${BoxFlex} {
      margin-top: 24px;
    }
  }
`;

const SectionProtocol = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px;
  }

  ${BoxFlex} {
    & > li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 48px;
    }
  }
`;

const BnLink = styled.button`
  width: 100%;
  display: block;
  padding: 34px 64px 33px 40px;
  border: solid 2px #1cabe2;
  background-color: #ffffff;
  margin-top: 96px;
  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    dt {
      width: 67%;
      text-align: left;
      ${Tit} {
        margin: 2px 0;
        display: block;
      }
    }
    dd {
      width: 33%;
    }
  }
  ${breakpoint(`tablet`)} {
    padding: 18px 24px 20px;
    margin-top: 72px;
    dl {
      flex-wrap: wrap;
      dt,
      dd {
        width: 100%;
      }
      dd {
        margin-top: 20px;
      }
    }
  }
`;
const SectionTerm = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px;
  }
  ${Box} {
    margin-bottom: 32px;
  }
  .btn-flex {
    margin-top: 32px;

    & > li {
      width: 33.33%;
    }

    ${Button} {
      width: 100%;
      height: 80px;
      line-height: 1.6;
      padding: 0 24px;
      display: flex;
      align-items: center;
      letter-spacing: -1.2px;

      .ico {
        width: 100%;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    ${SectionHeader} {
      padding-bottom: 48px;
    }

    .btn-flex {
      margin-top: 24px;

      & > li {
        width: 100%;
        margin-bottom: 24px;
        button {
          width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const SectionAgreement = styled(Section)`
  .btn-flex {
    & > li {
      width: 50%;
      ${ButtonMoreView} {
        width: 100%;
        display: block;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    .btn-flex {
      & > li {
        width: 100%;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
        ${ButtonMoreView} {
          line-height: 1.7;
          height: auto;
          padding: 14px 24px;
          padding-right: 50px;
        }
      }
    }
  }
`;
const ConventionOnTheRightsOfTheChild: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A014'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``);

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  return (
    <LayoutWithTitle
      location={location}
      title="아동권리협약"
      description="Convention on the Rights of the Child"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  전 세계 모든 어린이를 위한 약속, <br />
                  아동권리협약
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                1989년 11월 20일, 전 세계 지도자들은 유엔아동권리협약이라는
                어린이를 위한 역사적 약속을 이뤄냈습니다. 유엔아동권리협약은
                역사상 가장 많은 국가가 비준한 인권조약으로, 전 세계 어린이의
                삶을 변화시켰습니다. 어린이가 누려야 할 모든 권리를 담은
                유엔아동권리협약은 각 나라에서 어린이 상황을 개선하는 노력의
                기반이 됩니다. 아동권리협약은 전문과 54개 조항으로 구성되며
                제1조부터 42조까지 실제적인 아동권리 내용을, 제43조부터
                54조까지는 협약의 이행에 대한 내용을 담고 있습니다. 모든
                권리들은 서로 연관되어 있으며 모두 똑같이 중요합니다.
                아동권리협약을 보완하는 3개의 선택의정서도 있습니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle
              className="youtube-container"
              videoId="T82sfgXgdqA"
            />
          </PageComponent>

          <article>
            <Tit size="s2">아동권리협약 일반 원칙</Tit>

            <BoxFlex className="flex">
              <li>
                <dl>
                  <dt>
                    <Tit size="s4-1" color="sky">
                      비차별
                    </Tit>
                    <br />
                    Non-discrimination
                  </dt>
                  <dd>
                    <p className="dec">
                      모든 아동은 본인과 부모, 후견인의 인종, 피부색, 성별,
                      언어, 종교, 정치적 의견, 출신, 재산, 장애 여부, 태생, 신분
                      등과 관계없이 동등한 권리를 누려야 합니다.
                    </p>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>
                    <Tit size="s4-1" color="sky">
                      아동 최선의 이익
                    </Tit>
                    <br />
                    Best interests of the child
                  </dt>
                  <dd>
                    <p className="dec">
                      아동에게 영향을 미치는 모든 것을 결정할 때는 아동 최선의
                      이익을 최우선으로 고려해야 합니다.
                    </p>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>
                    <Tit size="s4-1" color="sky">
                      생존과 발달의 권리
                    </Tit>
                    <br />
                    The right to life, survival, and development
                  </dt>
                  <dd>
                    <p className="dec">
                      아동은 특별히 생존과 발달을 위해 다양한 보호와 지원을
                      받아야 합니다.
                    </p>
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>
                    <Tit size="s4-1" color="sky">
                      아동 의견 존중
                    </Tit>
                    <br />
                    Respect for the views of the child
                  </dt>
                  <dd>
                    <p className="dec">
                      아동은 자신의 잠재능력을 최대한 발휘할 수 있도록 적절한
                      사회활동에 참여할 기회를 갖고, 자신의 생활에 영향을 주는
                      일에 대해 의견을 말할 수 있어야 하며 그 의견을 존중받아야
                      합니다.
                    </p>
                  </dd>
                </dl>
              </li>
            </BoxFlex>
          </article>
        </Container>
      </SectionTop>

      <SectionProtocol className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">아동권리협약 선택의정서</Tit>
            </h2>
            <p className="header-dec">
              선택의정서(Optional protocol)는 아동권리협약을 보완합니다. 전 세계
              아동 학대와 착취를 막기 위해 2000년 유엔 총회는 아동권리협약에
              대한 두 가지 선택의정서를 채택하여 무력분쟁과 아동매매·성매매를
              통한 아동성착취·아동성착취물로부터 아동보호를 강화했습니다.
              2014년에 세 번째 선택의정서가 채택되어 아동이 아동권리위원회에
              직접 불만을 제기할 수 있게 되었습니다. 위원회는 제출된 청원서에
              따라 해당 주장을 조사하고 정부가 조치를 취하도록 지시할 수
              있습니다.
            </p>
          </SectionHeader>
          <BoxFlex className="flex m-hide">
            {boxData.map((row, index) => (
              <li key={index} className={index === 2 && `full`}>
                <dl>
                  <dt>
                    <Tit size="s4-1" color="sky">
                      {row.tit}
                    </Tit>
                  </dt>
                  <dd>
                    <p className="dec">{row.dec}</p>
                  </dd>
                </dl>
              </li>
            ))}
          </BoxFlex>
          <SwiperCustom className="pc-hide">
            <Swiper
              slidesPerView="1"
              scrollbar={{ draggable: true }}
              autoHeight={false}
            >
              {boxData.map((row, index) => (
                <SwiperSlide key={index}>
                  <dl>
                    <dt>
                      <Tit size="s4-1" color="sky">
                        {row.tit}
                      </Tit>
                    </dt>
                    <dd>
                      <p className="dec">{row.dec}</p>
                    </dd>
                  </dl>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperCustom>
        </Container>
      </SectionProtocol>

      <SectionTerm className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">유니세프와 아동권리협약</Tit>
            </h2>
            <p className="header-dec">
              유니세프는 전 세계 어린이를 위한 유엔기구로써 1946년 12월 11일
              창립된 이래 현재에 이르기까지 차별 없는 구호 정신으로 전 세계
              개발도상국 아동을 지원합니다. 유엔아동권리협약 45조에 명시된 바와
              같이 유니세프는 아동권리옹호를 주도하는 유엔기구입니다.
            </p>
          </SectionHeader>
          <Box>
            <Tit size="s4-1">[유엔아동권리협약 제 45조]</Tit>
            <p className="dec">
              이 협약의 효과적인 이행을 촉진하고 협약이 다루는 분야에서
              국제협력을 장려하기 위해 <br />
              가. 전문기구, 유니세프(유엔아동기금)를 비롯한 유엔기구들은 이 협약
              중 그들의 권한에 속하는 규정 이행과 관련된 논의에 대표를 파견할
              권리를 가진다. 위원회는 전문기구, 유니세프 및 위원회가 적절하다고
              판단하는 그 밖의 권한있는 기구에 대해 각 기구의 권한에 속하는
              분야에 있어 협약 이행에 관한 전문적인 자문 제공을 요청할 수 있다.
              위원회는 전문기구, 유니세프 및 다른 유엔기구들에게 그들의
              활동분야에 한해 협약 이행에 관한 보고서 제출을 요청할 수 있다.
            </p>
          </Box>
          <p className="dec">
            또한, 아동권리협약은 유니세프에게 협약 이행을 위한 역할을 부여하는
            최초의 인권조약입니다. 협약에 따라 유니세프는 아동권리위원회가
            당사국의 협약 이행 현황을 검토할 때(정부심의) 참석할 수 있습니다.
            유니세프는 전문적 조언을 제공하고 보고서를 제출하도록 요청받을 수
            있습니다. 아동권리위원회는 당사국이 유니세프의 기술 자문이나 지원을
            받도록 요청할 수 있습니다.
          </p>
          <ul className="btn-flex flex">
            <li>
              <Button
                ico="down"
                outline
                full
                onClick={() => {
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9taXNzaW9u&dispFileNm=%5BUNICEF%20Korea%5D%20%EC%9C%A0%EC%97%94%EC%95%84%EB%8F%99%EA%B6%8C%EB%A6%AC%ED%98%91%EC%95%BD%20%EC%86%8C%EC%B1%85%EC%9E%90.pdf&svrFileNm=%5BUNICEF%20Korea%5D%20%EC%9C%A0%EC%97%94%EC%95%84%EB%8F%99%EA%B6%8C%EB%A6%AC%ED%98%91%EC%95%BD%20%EC%86%8C%EC%B1%85%EC%9E%90.pdf`,
                  );
                }}
              >
                <span className="ico">
                  유엔아동권리협약 의무이행자용 소책자
                </span>
              </Button>
            </li>
            <li>
              <Button
                ico="down"
                outline
                onClick={() =>
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9taXNzaW9u&dispFileNm=%5BUNICEF%20Korea%5D%EC%9C%A0%EC%97%94%EC%95%84%EB%8F%99%EA%B6%8C%EB%A6%AC%ED%98%91%EC%95%BD%20%ED%8F%AC%EC%8A%A4%ED%84%B0.pdf&svrFileNm=%5BUNICEF%20Korea%5D%EC%9C%A0%EC%97%94%EC%95%84%EB%8F%99%EA%B6%8C%EB%A6%AC%ED%98%91%EC%95%BD%20%ED%8F%AC%EC%8A%A4%ED%84%B0.pdf`,
                  )
                }
              >
                <span className="ico">유엔아동권리협약 아이콘 포스터</span>
              </Button>
            </li>
            <li>
              <Button
                ico="down"
                outline
                full
                onClick={() =>
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9taXNzaW9u&dispFileNm=%5BUNICEF%20Korea%5D%EC%9C%A0%EC%97%94%EC%95%84%EB%8F%99%EA%B6%8C%EB%A6%AC%ED%98%91%EC%95%BD%20%EC%B9%B4%EB%93%9C.pdf&svrFileNm=%5BUNICEF%20Korea%5D%EC%9C%A0%EC%97%94%EC%95%84%EB%8F%99%EA%B6%8C%EB%A6%AC%ED%98%91%EC%95%BD%20%EC%B9%B4%EB%93%9C.pdf`,
                  )
                }
              >
                <span className="ico">유엔아동권리협약 카드</span>
              </Button>
            </li>
          </ul>

          <BnLink
            target="_blank"
            onClick={() => {
              open();
              setPopupTitle(`여러 언어로 보는 유엔아동권리협약`);
            }}
          >
            <dl>
              <dt>
                <Tit size="s3" color="sky">
                  유엔아동권리협약 바로가기
                </Tit>
                <Tit size="s2">여러 언어로 보는 유엔아동권리협약</Tit>
              </dt>
              <dd>
                <Image pcSrc={Bn} mobileSrc={Bn} />
              </dd>
            </dl>
          </BnLink>
        </Container>
        <GoalsPopup
          isOpen={popupOpen}
          PopTitle={PopupTitle}
          onRequestClose={close}
        />
      </SectionTerm>

      <SectionAgreement className="by-sub-main-layout">
        <Container>
          <SectionHeader
            className="with-desc"
            css={`
              ${breakpoint(640)} {
                padding-bottom: 48px;
              }
            `}
          >
            <h2>
              <Tit size="s1">아동권리협약의 작동 원리</Tit>
            </h2>
            <p className="header-dec">
              아동권리협약과 같은 국제인권조약 및 협약은 유엔 회원국 간의 협상
              과정을 통해 개발됩니다. 또한 개별 국가는 조약에 법적으로 구속될지
              여부를 스스로 결정합니다.
            </p>
          </SectionHeader>

          <AgreementContainer>
            <dl>
              <dt>
                <Tit size="s4-1" color="sky">
                  1 . 아동권리협약 비준과 보고 과정
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  국가가 아동권리협약을 지키기로 약속하는 방법에는
                  서명(Signature) 그리고 비준(Ratification)및 가입(Accession)에
                  의한 두 가지 방법이 있습니다. 국가가 아동권리협약 또는
                  선택의정서를 비준하거나 가입한다는 것은 협약을 이행하는 데
                  필요한 법률 및 정책을 채택하거나 변경하는 것을 포함해 협약에
                  명시된 권리를 존중, 보호 및 이행할 의무를 수락한다는
                  의미입니다.
                </p>
                <div className="dec-wrap">
                  <p className="dec">
                    <Tit size="s4" color="white">
                      서명(Signature)
                    </Tit>
                    은 협약 또는 의정서의 예비 승인을 의미합니다. 이 문서에
                    서명한다고 해서 구속력 있는 법적 의무가 생기지는 않지만
                    국내에서 조약을 검토하고 비준을 고려하려는 국가의 의도를
                    보여줍니다. 서명을 통해 조약의 당사국이 되는 것은 아니지만
                    조약의 대상과 목적을 침해하거나 침해하는 행위를 금지할
                    의무가 있습니다.
                  </p>
                  <p className="dec">
                    <Tit size="s4" color="white">
                      비준(Ratification) 또는 가입(Accession)
                    </Tit>
                    은 협약 조건에 법적으로 구속되는 합의를 의미합니다. 가입은
                    비준과 동일한 법적 효력을 갖지만 절차는 다릅니다. 비준의
                    경우 국가는 먼저 조약에 서명한 다음 비준합니다. 가입 절차의
                    경우 하나의 단계로 이루어져 있으며 서명 행위가 선행되지
                    않습니다. 비준 또는 가입을 위한 공식 절차는 국가의 입법
                    절차에 따라 다를 수 있습니다. 비준 또는 가입 이전에 국가는
                    조약과 국내법을 비교·검토하고 조약 준수를 위한 가장 적절한
                    방법을 고려합니다. 그리고 조약 체결 시 당사국은 일방적으로
                    유보를 선언할 수 있는데, 이는 조약 중 특정 조항에 대한 법적
                    효과를 제한하거나 변경하기 위한 조치입니다. 대한민국은
                    2024년 현재 아동권리협약 제40조 제2항(나)(5)를
                    유보하였습니다.
                  </p>
                  <p className="dec">
                    <Tit size="s4" color="white">
                      아동권리협약 보고 과정
                    </Tit>
                    국가가 아동권리협약을 비준했다는 것은 자국의 아동권리 증진에
                    대한 노력을 유엔아동권리위원회에 주기적으로 보고하는 것에
                    동의했다는 의 미입니다. 아동권리협약 제44조에 따라 당사국은
                    협약 비준 2년 이내에 2년차에, 그리고 그 이후 매 5년마다
                    아동권리협약의 이행 현황을 아동권리위원회에 보고해야 합니다.
                    당사국이 협약 이행 보고서(흔히 국가보고서, 정부보고서라고
                    불리는 문서)를 아동권리위원회에 제출하면 해당 보고서를
                    검토하고 유니세프, 어린이, 비정부기구와 같은
                    이해관계자들로부터 정보를 수집하여 해당 보고서에 대한
                    최종견해를 발표합니다. 이를 아동권리위원회의
                    최종견해(Concluding observations)이라 부르는데, 여기에는
                    당사국이 아동권리 증진을 위해 노력한 내용을 재확인하고,
                    우려되는 부분을 전하며, 이를 개선하기 위해 당사국이
                    시행하기를 촉구하는 권고사항들이 담겨있습니다.
                  </p>
                </div>

                <ProcessContainer>
                  <li>
                    <div className="box">
                      <span className="no">1</span>
                      <p className="dec">국가보고서 제출</p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <span className="no">2</span>
                      <p className="dec">
                        NGO와 아동이 작성한 <br />
                        보고서 제출
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <span className="no">3</span>
                      <p className="dec">
                        유엔아동권리위원회와 아동, NGO, 옴부즈퍼슨, UN 기구와
                        만남 [사전심의, 아동회의]
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <span className="no">4</span>
                      <p className="dec">
                        아동권리위원회가 정부에게 <br />
                        질문을 송부 [질의 목록 List of issues]
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <span className="no">5</span>
                      <p className="dec">
                        질의목록에 대해 정부가 <br />
                        문서로 답변
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <span className="no">6</span>
                      <p className="dec">
                        유엔아동권리위원회와 정부가 만나 <br />
                        아동권리 협약 이행현황 검토 [정부심의]
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <span className="no">7</span>
                      <p className="dec">
                        모든 보고서와 회의 내용을 검토하여 유엔아동권리위원회의
                        최종견해 배포
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <span className="no">8</span>
                      <p className="dec">최종견해의 이행</p>
                    </div>
                  </li>
                </ProcessContainer>
                <p className="dec">
                  아동권리협약은 9개 핵심인권조약 중 가장 많이 보고된
                  협약입니다. 아동권리협약을 비준한 196개의 당사국 중 가장
                  최근에 비준한 소말리아와 남수단을 제외하고 적어도 한 번 이상
                  아동권리위원회에 아동권리협약 이행 현황을 보고했습니다. 이러한
                  높은 보고율은 보고과정에 참여하는 국가에 대한 유니세프의 기술
                  및 재정적 지원 덕분입니다. 그리고 아동권리위원회는 당사국의
                  보고를 돕기 위해 지침을 발표했습니다. 이러한 지침은 다음에서
                  확인할 수 있습니다.
                </p>
                <Box>
                  <ul className="blit-list">
                    <li>
                      <LinkSafe
                        className="link-blank"
                        target="_blank"
                        to="https://tbinternet.ohchr.org/_layouts/15/treatybodyexternal/Download.aspx?symbolno=CRC/C/5&Lang=en"
                      >
                        아동권리협약 최초 이행 국가보고서 작성 지침(비준 직후
                        2년차에 제출)
                      </LinkSafe>
                    </li>
                    <li>
                      <LinkSafe
                        className="link-blank"
                        target="_blank"
                        to="https://tbinternet.ohchr.org/_layouts/15/treatybodyexternal/Download.aspx?symbolno=CRC/C/58/REV.3&Lang=en"
                      >
                        아동권리협약 정기 이행 국가보고서 작성 지침(매 5년마다
                        제출)
                      </LinkSafe>
                    </li>
                    <li>
                      <LinkSafe
                        className="link-blank"
                        target="_blank"
                        to="https://tbinternet.ohchr.org/_layouts/15/treatybodyexternal/Download.aspx?key=92g0+9FnI5fX/ePqHxWObLX2P5GEb99twsPrh/K2aa/53Vhodt3zvExHQntDN0+H&Lang=en"
                      >
                        아동의 무력분쟁 관여에 대한 선택의정서(OPAC) 최초 이행
                        국가보고서 작성 지침
                      </LinkSafe>
                    </li>
                    <li>
                      <LinkSafe
                        className="link-blank"
                        target="_blank"
                        to="https://tbinternet.ohchr.org/_layouts/15/treatybodyexternal/Download.aspx?key=92g0+9FnI5fX/ePqHxWObLX2P5GEb99twsPrh/K2aa8ca+rWNDc4HydXFyv8hknx&Lang=en"
                      >
                        아동매매·성매매를 통한 아동성착취·아동성착취물에 관한
                        선택의정서(OPSC) 최초 이행 국가보고서 작성 지침
                      </LinkSafe>
                    </li>
                  </ul>
                </Box>

                <p className="dec">
                  아동권리협약 이행 현황을 담은 국가보고서를 제출하는 것 외에도
                  당사국은 공통핵심문서를 최신 상태로 유지해야 합니다.
                  공통핵심문서 (Common Core Document)의 초안 작성 또는 업데이트
                  지침은 다음에서 찾을 수 있습니다.
                  <LinkSafe
                    target="_blank"
                    to="https://undocs.org/HRI/GEN/2/Rev.6"
                    className="link-blank"
                  >
                    (공통핵심문서에 대한 지침)
                  </LinkSafe>
                  <strong>
                    대한민국 역시 공통핵심문서를 제출했으며 최신 버전은
                    유엔인권최고대표사무소{` `}
                  </strong>
                  <LinkSafe
                    target="_blank"
                    to="https://tbinternet.ohchr.org/_layouts/15/TreatyBodyExternal/CoreDocuments.aspx"
                    className="link-blank"
                  >
                    (OHCHR) 웹사이트
                  </LinkSafe>
                  에서 찾을 수 있습니다. <br />
                  <br />
                  <strong>
                    아동권리협약은 아동에 관한 모든 정부 조치의 주요 기준이자
                    영감이 되어야 합니다. 협약을 비준함으로써 국가는 협약에
                    포함된 권리의 완전한 실현을 위해 "모든 적절한 입법, 행정 및
                    기타 조치"를 수행할 것을 약속합니다.
                  </strong>
                </p>
              </dd>
            </dl>

            <dl>
              <dt>
                <Tit size="s4-1" color="sky">
                  2. 어린이의 보고 과정 참여
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  권리의 주체이자 당사자인 어린이 역시 아동권리협약 보고 과정에
                  참여할 수 있습니다. 아동권리위원회는 당사국 정부, 유니세프,
                  비정부기구, 옴부즈퍼슨을 통해 아동권리협약 이행 현황에 대해
                  보고를 받지만 그중에서도 어린이가 자신의 권리에 대해 스스로
                  말하는 것 역시 매우 중요하게 생각합니다. 어린이가 보고 과정에
                  참여하는 것은 유엔아동권리위원회에 정부가 나의 권리를 얼마나
                  잘 보호하고 있는지를 알릴 수 있는 기회입니다. 대한민국에서는
                  2010년과 2018년에 어린이의 목소리가 담긴 보고서가
                  제출됐습니다.
                </p>
              </dd>
            </dl>

            <dl>
              <dt>
                <Tit size="s4-1" color="sky">
                  3. 비정부기구의 참여
                </Tit>
              </dt>
              <dd>
                <p className="dec">
                  비정부기구는 아동권리협약과 그 목표에 대한 대중의 인식을
                  높이고 협약의 이행을 지원하는 데 중요한 역할을 합니다.
                  아동권리협약은 인권조약 중 최초로 보고 및 모니터링 과정에
                  비정부기구가 참여하도록 특별히 초대함으로써 이러한 기여를
                  인정합니다. 일부 당사국은 국가보고서 작성 및 보고 과정에서
                  광범위하게 비정부기구와 협의하고 그들의 기여를 보고서에
                  포함시키기도 합니다. 또한 개별 비정부기구 또는 연합은
                  아동권리위원회의 면밀한 현황 검토를 위해
                  시민사회보고서(민간보고서)를 제출할 수 있습니다.
                </p>
              </dd>
            </dl>
          </AgreementContainer>
          <ul className="btn-flex flex">
            <li>
              <ButtonMoreView
                mode="sky"
                href="/what-we-do/advocacy-for-children/crc-implementation"
              >
                한국의 협약 이행과 유니세프 역할 알아보기
              </ButtonMoreView>
            </li>
            <li>
              <ButtonMoreView
                mode="sky"
                href="/what-we-do/database?category=KW05#tagBox"
              >
                아동권리협약 관련 자료실 바로가기
              </ButtonMoreView>
            </li>
          </ul>
        </Container>
      </SectionAgreement>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A014" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default ConventionOnTheRightsOfTheChild;

import { PopCcontainer, PopupTitle } from '../Css/Pop';
import LinkSafe from '../LinkSafe';
import Modal from '../Modal';
import { Tit } from '../Titles';
import Lang3 from '@/assets/img/flag/lang_ae@2x.png';
import Lang13 from '@/assets/img/flag/lang_audio@2x.png';
import Lang12 from '@/assets/img/flag/lang_braille@2x.png';
import Lang2 from '@/assets/img/flag/lang_cn@2x.png';
import Lang1 from '@/assets/img/flag/lang_en@2x.png';
import Lang6 from '@/assets/img/flag/lang_es@2x.png';
import Lang4 from '@/assets/img/flag/lang_fr@2x.png';
import Lang8 from '@/assets/img/flag/lang_id@2x.png';
import Lang9 from '@/assets/img/flag/lang_jp@2x.png';
import Lang10 from '@/assets/img/flag/lang_kz@2x.png';
import Lang5 from '@/assets/img/flag/lang_ru@2x.png';
import Lang11 from '@/assets/img/flag/lang_th@2x.png';
import Lang7 from '@/assets/img/flag/lang_vn@2x.png';
import { BtnClose } from '@/components/Button';
import Image from '@/components/Image';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface ModalProps {
  isOpen?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 닫힘요청시 콜백
  onRequestClose: () => void;
  maxWidth?: string;
}

const langData = [
  {
    id: 1,
    flag: Lang1,
    txt: `영어(English)`,
    link: `https://weshare.unicef.org/archive/ENGLISH---CRC-A4-booklet-2AM408TCH6MC.html`,
  },
  {
    id: 2,
    flag: Lang2,
    txt: `중국어(中文)`,
    link: `https://weshare.unicef.org/archive/-2AM408TCHNFM.html`,
  },
  {
    id: 3,
    flag: Lang3,
    txt: `아랍어(العربية)`,
    link: `https://weshare.unicef.org/archive/-2AM408TUSGXD.html`,
  },
  {
    id: 4,
    flag: Lang4,
    txt: `프랑스어(Français)`,
    link: `https://weshare.unicef.org/archive/-2AM408TCH1X6.html`,
  },
  {
    id: 5,
    flag: Lang5,
    txt: `러시아어(Русский)`,
    link: `https://weshare.unicef.org/archive/-2AM408TQKI_D.html`,
  },
  {
    id: 6,
    flag: Lang6,
    txt: `스페인어(Español)`,
    link: ``,
  },
  {
    id: 7,
    flag: Lang7,
    txt: `베트남어(Tiếng Việt)`,
    link: `https://infogram.com/crc-30-vn-1h0n25vxe31z6pe`,
  },
  {
    id: 8,
    flag: Lang8,
    txt: `인도네시아어 <br />(Bahasa Indonesia)`,
    link: `https://www.unicef.org/indonesia/id/konvensi-hak-anak-versi-anak-anak`,
  },
  {
    id: 9,
    flag: Lang9,
    txt: `일본어(日本語)`,
    link: `https://www.unicef.or.jp/about_unicef/about_rig/pdf/CRC30icons_JPN.pdf`,
  },
  {
    id: 10,
    flag: Lang10,
    txt: `카자흐어(қазақша)`,
    link: `https://www.unicef.org/kazakhstan/sites/unicef.org.kazakhstan/files/2019-12/%D0%9A%D0%BE%D0%BD%D0%B2%D0%B5%D0%BD%D1%86%D0%B8%D1%8F%20%D0%9A%D0%90%D0%97.pdf`,
  },
  {
    id: 11,
    flag: Lang11,
    txt: `태국어(ภาษาไทย)`,
    link: `https://www.unicef.org/thailand/sites/unicef.org.thailand/files/2019-10/Convention%20on%20the%20Rights%20of%20the%20Child_TH_FINAL.pdf`,
  },
  {
    id: 12,
    flag: Lang12,
    txt: `점자`,
    link: `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9taXNzaW9u&dispFileNm=유니세프_손으로_읽는_아동권리_점자판.zip&svrFileNm=유니세프_손으로_읽는_아동권리_점자판.zip`,
  },
  {
    id: 13,
    flag: Lang13,
    txt: `오디오`,
    link: `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9taXNzaW9u&dispFileNm=안성기_대사님이_읽어주는_유엔아동권리협약.mp3&svrFileNm=안성기_대사님이_읽어주는_유엔아동권리협약.mp3`,
  },
];
const PopContent = styled.div`
  padding-bottom: 32px;
  .m-main {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
  }
`;
const LangList = styled.ul`
  margin: 0 -15px;
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  & > li {
    padding: 0 15px;
    width: 33.33%;
    margin-bottom: 64px;
  }

  .plain-item {
    display: inline-block;
    line-height: 2;

    ${Image} {
      display: block;
      margin: 0 auto;
    }

    .dec {
      margin-top: 8px;
      text-align: center;
    }
  }

  .link-item {
    display: inline-block;
    line-height: 2;

    ${Image} {
      display: block;
      margin: 0 auto;
    }

    .dec {
      margin-top: 8px;
      text-align: center;
    }

    &:hover {
      .dec {
        text-decoration: underline;
      }
    }
  }
`;

const GoalsPopup: FC = ({ onRequestClose, ...props }) => (
  <Modal onRequestClose={onRequestClose} maxWidth="640px" {...props}>
    <PopCcontainer>
      <PopupTitle>
        <h2>
          <Tit
            size="s3"
            color="sky"
            dangerouslySetInnerHTML={{ __html: props.PopTitle }}
          />
        </h2>
        <BtnClose onClick={() => onRequestClose()}>
          <span className="a11y">닫기</span>
        </BtnClose>
      </PopupTitle>
      <PopContent>
        <LangList>
          {langData.map((row) =>
            row.link === `` ? (
              <li key={row.id}>
                <div className="plain-item">
                  <Image pcSrc={row.flag} mobileSrc={row.flag} />
                  <p
                    className="dec"
                    dangerouslySetInnerHTML={{ __html: row.txt }}
                  />
                </div>
              </li>
            ) : (
              <li key={row.id}>
                <LinkSafe to={row.link} className="link-item" target="_blank">
                  <Image pcSrc={row.flag} mobileSrc={row.flag} />
                  <p
                    className="dec"
                    dangerouslySetInnerHTML={{ __html: row.txt }}
                  />
                </LinkSafe>
              </li>
            ),
          )}
        </LangList>
      </PopContent>
    </PopCcontainer>
  </Modal>
);

export default GoalsPopup;
